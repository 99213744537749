<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="filterResult(null)">
        <div id="filter-card" class="row py-3 px-4" v-if="filters">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 my-2 row px-0 mx-0">
                <input-filter
                    :helper-text="$t('general.search')"
                    :model.sync="filters.search"
                    :placeholder="$t('general.search_filter')"
                    title="general.search"
                ></input-filter>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.start_date')"
                      :model.sync="filters.start_date"
                      name="start_time"
                      title="general.start_date">
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.end_date')"
                      :model.sync="filters.end_date"
                      name="end_date"
                      title="general.end_date">
                  </date-filter>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-md-12 my-md-0">
                    <select-filter :helper-text="$t('invoice.invoice_type')" title="invoice.invoice_type"
                      :model.sync="filters.category_id" :item-per-row="1" :max="1" 
                      :not-list="true" :values="invoicesTypes" height="30vh" style="margin-bottom: 0 !important;" 
                      width="200px"></select-filter>
                  </div>
              </div>
              <div
                  class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                  style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                          @click="filterResult(null)">{{
                      $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                          @click="resetFilters">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="row w-100 mx-0 py-5 pl-xl-12" v-if="filters">
            <div :class="{'bg-primary text-white custom-tab-box': itemType==1 && is_service == null && invoice_status_id == null}"
                 class="col-xl-2 col-lg-2 col-md-2 col-sm-2 py-3 cursor-pointer text-xl font-weight-bolder text-center border primary-border-1px border-right-0 border-radius-left-top-10px border-radius-left-bottom-10px"
                 @click="changeSelection(1)">
              {{ $t('invoice.waiting_invoice') }}
            </div>
            <div :class="{'bg-primary text-white custom-tab-box':  itemType==5 }"
                 class="col-xl-2 col-lg-2 col-md-2 col-sm-2 py-3 cursor-pointer text-xl font-weight-bolder text-center border primary-border-1px border-right-0"
                 @click="changeSelection(5)">
                 {{ $t('invoice.packing_lists_to_be_billed') }}
            </div>
            <div :class="{'bg-primary text-white custom-tab-box': itemType==2}"
                 class="col-xl-2 col-lg-2 col-md-2 col-sm-2 py-3 cursor-pointer text-xl font-weight-bolder text-center border primary-border-1px border-right-0"
                 @click="changeSelection(2)">
              {{ $t('invoice.cutting_invoice') }}
            </div>
            <div :class="{'bg-primary text-white custom-tab-box': itemType==3}"
                 class="col-xl-3 col-lg-3 col-md-3 col-sm-3 py-3 cursor-pointer text-xl font-weight-bolder text-center border primary-border-1px border-right-0"
                 @click="changeSelection(3)">
              {{ $t('invoice.waybills_to_be_linked_to_the_invoice') }}
            </div>
            <div :class="{'bg-primary text-white custom-tab-box': +is_service === 1 && +invoice_status_id === 21}"
                 class="col-xl-2 col-lg-2 col-md-2 col-sm-2 cursor-pointer text-xl font-weight-bolder text-center py-3 border primary-border-1px border-radius-right-top-10px border-radius-right-bottom-10px"
                 @click="changeSelection(4)">
              {{ $t('invoice.canceled_invoice') }}
            </div>
          </div>
          <div class="px-5 py-3 table-responsive">
            <data-table
                id="table"
                :bordered="false"
                :borderless="true"
                :fields="fields"
                :hover="false"
                :items="formattedItems"
                :no-border-collapse="true"
                :outlined="false"
                :responsive="false"
                :striped="false"
                :infiniteId="infiniteId"
                tableVariant="''"
                @hitBottom="onHitBottom"
                @onClickMultiSelected="onClickMultiSelected"
                @rowClicked="onClickedRow"
                :sortBy.sync="order" 
                :sortDesc.sync="sort"
            ></data-table>
            <div class="fixed" style="position: fixed !important; bottom: 20px; right: 150px;" v-if="isUserGranted('Invoice', ['create'], false)">
              <div class="w-60px h-60px" @click="openNewOutgoing">
                <button-with-icon
                    :icon-name="null"
                    :text="$t('invoice.add_new_outgoing')"
                    class="w-200px text-primary"
                    size="lg"
                    @onClick="openNewOutgoing"
                ></button-with-icon>
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import {mapGetters, mapMutations} from 'vuex'
import {HANDLE_INFINITE_SCROLL, LOADING, SET_ITEMS, SET_LOADING, FILTER, SET_FILTER} from "@/core/services/store/invoice/outgoing_invoice.module";
import moment from "moment";
import DateFilter from "@/assets/components/filters/DateFilter";
import InputFilter from "@/assets/components/filters/InputFilter";
import DataTable from "@/assets/components/dataTable/DataTable";
import {LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import * as _ from "lodash";
import {INVOICE_OUTGOING_INDEX_END_DATE_STORAGE_NAME, INVOICE_OUTGOING_INDEX_START_DATE_STORAGE_NAME} from "@/core/storage/storage-names"

export default {
  name: "TaskAction",
  components: {
    DashboardBox,
    DateFilter,
    InputFilter,
    ButtonWithIcon,
    DataTable,
    SelectFilter
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
    fields() {
      let base = [
        {
          key: 'date',
          label: this.$t('invoice.date'),
          sortable: false,
          class: 'text-center border border-top-0 border-left-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'order_number',
          label: this.$t('invoice.order_number'),
          sortable: false,
          class: 'text-center border border-top-0 border-left-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'company_name',
          label: this.$t('invoice.company_name'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'invoice_no',
          label: this.$t('invoice.invoice_number'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'unit_price',
          label: this.$t('invoice.unit_price'),
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'vat',
          label: this.$t('invoice.vat'),
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'content',
          label: this.$t('invoice.content'),
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'description',
          label: this.$t('invoice.description'),
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        },
        {
          key: 'amount',
          label: this.$t('invoice.amount'),
          sortable: true,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        }
      ];

      if (this.filters && !this.filters.invoice_status_id && !this.filters.is_service) {

        base.push({
          key: 'selected_options',
          label: this.$t('invoice.action'),
          sortable: false,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        });
      }

      if (this.filters && +this.filters.invoice_status_id === 23 && +this.filters.is_service === 1) {
        base.push({
          key: 'selected_options',
          label: this.$t('invoice.action'),
          sortable: false,
          class: 'text-center border border-top-0 font-weight-bolder font-size-lg',
          tdClass: "cursor-pointer"
        });
      }

      return base;
    },
    formattedItems() {
      let self = this;
      try {
        if (+self.itemType === 2) {
          if (!this.items || this.items.data === null || this.items.data === undefined) return [];
          return this.items.data.map(invoice => {
            return {
              'id': invoice.id,
              'date': moment(invoice.created_at, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
              'order_number': invoice.waybillable ? invoice.waybillable.order_number : '',
              'company_name': invoice.supplier_company ? invoice.supplier_company.name_for_accounting : '-',
              'invoice_type': invoice.invoice_type.translations[0].name,
              'invoice_kind': invoice.invoice_category ? invoice.invoice_category.translations[0].name : '',
              'invoice_no': invoice.invoice_no,
              'amount': invoice.total_amount,
              'unit_price': invoice.currency_unit ? invoice.currency_unit.code : '-',
              'currency_unit': invoice.currency_unit ? invoice.currency_unit.rate : '-',
              'vat': invoice.vat ? invoice.vat.rate : '-',
              'content': invoice.description ? invoice.description : '---',
              'description': +invoice.invoice_status_id === 19 ? 'İptal Edildi' : '',
              'selected_option': null,
            }
          })
        }
        if (+self.itemType === 1) {
          if (!this.items || !this.items.data.length) return [];
          return this.items.data.map(invoice => {
            if (invoice.hasOwnProperty('supplier_company_id')) {
              return {
                'id': invoice.id,
                'date': moment(invoice.created_at, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
                'order_number': invoice.order_numbers ? JSON.parse(invoice.order_numbers).join() : '-',
                'company_name': invoice.supplier_company.name,
                'invoice_type': "-",
                'invoice_kind': invoice.invoice_type.translation.name,
                'invoice_no': invoice.invoice_no?invoice.invoice_no:'-',
                'amount': invoice.total_amount,
                'unit_price': "-",
                'currency_unit': "-",
                'vat': "-",
                'content': "-",
                'description': invoice.accounting_description?invoice.accounting_description:'-',
                'selected_option': null,
              }
            }
          })
        }

        if (+self.itemType === 5) {
          if (!this.items || !this.items.data.length) return [];
          return this.items.data.map(invoice => {
            return {
              'id': invoice.id,
              'date': moment(invoice.created_at, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
              'order_number': invoice.orderable ? invoice.orderable.order_number : '-',
              'company_name': invoice.orderable.company.name,
              'invoice_type': "-",
              'invoice_kind': "-",
              'invoice_no': "-",
              'amount': invoice.amount,
              'unit_price': "-",
              'currency_unit': "-",
              'vat': "-",
              'content': "-",
              'description': '',
              'selected_option': null,
            }
          })
        }

        if (+self.itemType === 3) {
          if (!this.items || this.items.data === null || this.items.data === undefined) return [];
          return this.items.data.map(invoice => {
            let orderNumbers = _.uniq(_.map(invoice.transactions, 'waybillable.order_number'));
            return {
              'id': invoice.id,
              'date': moment(invoice.transaction_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
              'order_number': orderNumbers.join(","),
              'company_name': invoice.supplier_company ? invoice.supplier_company.name : '-',
              'invoice_type': '-',
              'invoice_kind': '-',
              'invoice_no': invoice.document_number,
              'amount': invoice.transactions_sum_net_amount,
              'unit_price': '-',
              'currency_unit': '-',
              'vat': '-',
              'content': '-',
              'description': invoice.description,
              'selected_option': null,
            }
          })
        }
      }
      catch (e) {
        return [];
      }
    },
    invoicesTypes(){
      let self = this;
      let filterStatusesItem=null;
      try {
        // Bekleyen Faturalar
        if (+self.itemType === 1 || +self.itemType === 2 || +self.itemType === 4) {
          filterStatusesItem=[
              {
                id:5,
                name:'İade Faturaları'
              },
              {
                id:7,
                name:'Yansıtma Faturaları'
              }
          ];
        }
        // Çeki Listesi
        if (+self.itemType === 5) {
          
        }
        // İrsalye bağlanacak Faturalar
        if (+self.itemType === 3) {

        }
        filterStatusesItem=this.convertArrayToObjectByKey(filterStatusesItem, 'id', 'name');
        return filterStatusesItem;
      }
      catch (e) {
        return [];
      }
    },
  },
  data() {
    return {
      url: 'api/invoice',
      urlOrders: 'api/invoices/get-invoice-billable-orders',
      urlWaybillOrders: 'api/invoices/get-invoice-waybillable-orders',
      items: {data: []},
      startDate: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
      endDate: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
      page: 1,
      perPage: 25,
      itemType: 1,
      invoice_status_id: null,
      is_service: null,
      select_packing_list:false,
      infiniteId:1,
      sort:"desc",
      order: "id",
    };
  },
  methods: {
    ...mapMutations({
      'setFilter': SET_FILTER,
    }),
    changeSelection(type) {
      this.infiniteId++;
      if (type === 1) {
        this.is_service = null;
        this.invoice_status_id = null;
        this.itemType = 1;
        this.select_packing_list=false;
      } else if (type === 2) {
        this.is_service = 1;
        this.invoice_status_id = 43;
        this.itemType = 2;
        this.select_packing_list=false;
      } else if (type === 3) {
        this.is_service = 1;
        this.invoice_status_id = 23;
        this.itemType = 3;
        this.select_packing_list=false;
      } else if (type === 4) {
        this.is_service = 1;
        this.invoice_status_id = 21;
        this.itemType = 1;
        this.select_packing_list=false;
      }else if (type === 5) {//çeki listeleri ile faturalar ayrıldı
        this.is_service = null;
        this.invoice_status_id = null;
        this.itemType = 5;
        this.select_packing_list=true;
      }
       else {
        this.is_service = null;
        this.invoice_status_id = null;
        this.itemType = 1;
        select_packing_list=false;
      }

      this.$set(this.filters, 'invoice_status_id', this.invoice_status_id)
      this.$set(this.filters, 'is_service', this.is_service)
      this.$set(this.filters, 'select_packing_list', this.select_packing_list)

      this.items = [];
      setTimeout(this.onHitBottom, 100);
    },
    openNewOutgoing(value) {
      this.$router.push({
        name: 'invoice.add_outgoing',
      });
    },
    onClickedRow(item, index, event) {
      if (this.isUserGranted('Invoice', ['create', 'update'])) {
        let eventType = event.target.tagName;
        if (eventType == 'TD') {
          this.$router.push({
            name: 'invoice.details',
            params: {
              id: this.items.data[index].id,
            }
          });
        }
      }
    },
    onClickMultiSelected({id, value}) {
      let self = this;
      if (this.isUserGranted('Invoice', ['create', 'update'])) {
        let item = self.items.data.find(item => +item.id === +id);
        self.$router.push({
          name: 'invoice.add_outgoing',
          query: {
            id: id,
            type: value[0],
            orderNumber: item.order_number
          }
        });
      }
    },
    filterResult() {
      localStorage.setItem(INVOICE_OUTGOING_INDEX_END_DATE_STORAGE_NAME, moment(this.filters.start_date));
      localStorage.setItem(INVOICE_OUTGOING_INDEX_START_DATE_STORAGE_NAME, moment(this.filters.end_date));

      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onHitBottom();
      if (self.state) {
        setTimeout(() => {
          self.state.reset();
        }, 100);
      }
    },
    onHitBottom($state = null) {
      let self = this;
      let url = this.url;

      if (+self.itemType === 1 || +self.itemType === 5) {
        url = this.urlOrders;
      }

      if (+self.itemType === 3) {
        url = this.urlWaybillOrders;
      }

      let filters = _.cloneDeep(this.filters);

      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'order', this.order);
      this.$set(filters, 'sort', this.sort);

      delete filters.is_service;

      this.$store.dispatch(HANDLE_INFINITE_SCROLL, {
        url: url,
        filters: filters,
        '$state': $state,
      }).then(result => {
        if (result.status) {
          self.items = result.data;
          if (!(result.data && result.data.data && result.data.data.length)) {
              $state.complete();
            }
          if ($state) {
            $state.loaded();
          }
        } else {
          if ($state) {
            $state.complete();
          }
        }
      })
    },
    resetFilters() {
      this.sort="desc";
      this.order= "id";
      this.setFilter({
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
        search: null,
        invoice_status_id: null,
        is_service: null,
        sort: "",
        order: "",
        select_packing_list:false,
      })
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('general.dashboard'), route: 'dashboard'},
      {title: this.$t('invoice.invoices')}
    ]);

    if (this.isUserGranted('Invoice', ['viewAny'])) {
      this.select_packing_list=false;
      if (!this.filter) {
        this.resetFilters();
        this.$set(this.filters, 'invoice_status_id', this.invoice_status_id)
        this.$set(this.filters, 'is_service', this.is_service)
      } else {
        this.invoice_status_id = this.filters.invoice_status_id;
        this.is_service = this.filters.is_service;
      }
      this.filters.end_date = localStorage.hasOwnProperty(INVOICE_OUTGOING_INDEX_START_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(INVOICE_OUTGOING_INDEX_START_DATE_STORAGE_NAME))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(INVOICE_OUTGOING_INDEX_END_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(INVOICE_OUTGOING_INDEX_END_DATE_STORAGE_NAME))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);
      setTimeout(this.onHitBottom, 500);
    }
  },
  watch: {
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
  }
}
</script>

<style scoped>

</style>
